html {
    font-family: sans-serif;
    font-size: 14px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: arial, sans-serif;
    table-layout: fixed;
    width: 100%;
    text-align: start;
  }
  
  thead {
    background: lightgray;
    padding: 30px;
  }
  
  tr {
    border-bottom: 1px solid lightgray;
    padding: 30px;
  }
  
  th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 10px 10px;
    text-align: left;
  }
  
  td {
    padding: 6px;
  }
  
  .container {
    border: 1px solid lightgray;
    margin: 1rem auto;
  }
  
  .app {
    margin: 1rem auto;
    text-align: center;
  }
  