table {
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid lightgray ;
  }
      @media
        only screen 
      and (max-width: 760px), (min-device-width: 768px) 
      and (max-device-width: 1024px)  {
  
          /* Force table to not be like tables anymore */
          table, thead, tbody, th, td, tr {
              display: block;
              
          }
  
          thead{
            display: none;
          }

          /* Hide table headers (but not display: none;, for accessibility) */
          thead tr {
              position: absolute;
              top: -9999px;
              left: -9999px;
              
          }
  
      tr {
        margin: 0 0 1rem 0;
      }
        
      tr:nth-child(odd) {
        background: #ccc;
      }
      
          td {
              /* Behave  like a "row" */
              border: none;
              border-bottom: 1px solid #eee;
              position: relative;
              padding-left: 50%;
          }
  
          td:before {
              /* Now like a table header */
             /*  position: absolute; */
              /* Top/left values mimic padding */
              top: 0;
              left: 6px;
              width: 100%;
              padding-right: 10px;
              white-space: nowrap;
          }
  
          /*
          Label the data
      You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
          */
          td:nth-of-type(1):before { content: "ID"; }
          td:nth-of-type(2):before { content: "Пользователь"; }
          td:nth-of-type(3):before { content: "Время"; }
          td:nth-of-type(4):before { content: "Тип"; }
          td:nth-of-type(5):before { content: "Сумма ДО"; }
          td:nth-of-type(6):before { content: "Сумма После"; }
    
      }