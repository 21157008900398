.test_1:after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(255, 193, 7) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}

.test_1::before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(210.04deg, rgb(255, 193, 7) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
    cursor: pointer;
}

.test_1 {
    /*     background-color: rgb(255, 255, 255) !important;
    color: rgb(54, 65, 82) !important; */
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    box-shadow: none !important;
    background-image: none !important;
    border-radius: 12px !important;
    border: none rgba(144, 202, 249, 0.145) !important;
    overflow: hidden !important;
    position: relative !important;
}

.accordion_test {
    padding: 0px !important;
}

/* .MuiLoadingButton-loadingIndicator {
    color: #FFF  !important;
  } */

._link_box {
    background: red;
}


/* .css-zkgv5p-MuiLinearProgress-bar1 {

    background-color: #FFC108 !important
} */